import React from "react";
import { Helmet } from "react-helmet"
import { Container, Row, Col } from 'react-bootstrap'

import Layout from "../components/layout"

import heroImg from '../images/books/Winter-2021-header.jpg'
import otherBooksImg from '../images/books/book-selection.png'

import narrativeImg from '../images/writing-packs/narrative.jpg'
import visualLiteracyImg from '../images/writing-packs/visual-literacy.jpg'
import poetryImg from '../images/writing-packs/poetry.jpg'
import nonFictionImg from '../images/writing-packs/non-fiction.jpg'

import quoteOpenImg from '../images/books/quote-open.svg'
import quoteCloseImg from '../images/books/quote-close.svg'


const BooksPage = ({ location }) => {
    
    return (
        <>
        <Helmet>
            <title>BoomWriter UK Books</title>
            <meta name="description" content="A great set of books your class can write this winter" />
        </Helmet>
        <Layout location={location}>
            <Container className="books">
                <div className="booksHero">
                    <div className="booksHero_text">
                        <h1><span className="booksHero_text_intro fontWeight-reg">Your class</span> Published!</h1>
                        <p>We have a great range of writing events and books for your classroom. BoomWriter UK is simple to use, highly engaging and affordable.</p>
                        <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="primary-btn booksHero_btn">Start Your Book</a>
                    </div>
                    <img src={heroImg} className="booksHero_img" alt="Winter Books on BoomWriter UK" />
                </div>
            </Container>
            <section className="booksOther">
                <Container>
                    <div className='booksOtherIntro'>
                        <div className="booksOtherIntro_main">
                            <h2 className="headingHero">A book for your class</h2>
                            <p>We have a wide range of book templates that will suit your class including narrative, poetry, non-fiction and visual literacy</p>
                            <p>You’ll be able to edit the book to suit your class and in some cases you can even create your own custom book project.</p>
                        </div>
                        <img src={otherBooksImg} alt="BoomWriter UK Books" className="booksOtherIntro_aside" />
                    </div>
                    <div className="booksOtherGrid">
                        <div className="booksOtherGrid_item">
                            <h3 className="booksOtherGrid_item-title">Narrative</h3>
                            <img src={narrativeImg} alt="Narrative Story Books" />
                            <p>Covering a wide range of narrative types, these books will allow the narrative to develop as your class sees fit.</p>
                        </div>
                        <div className="booksOtherGrid_item">
                            <h3 className="booksOtherGrid_item-title">Visual Literacy</h3>
                            <img src={visualLiteracyImg} alt="Visual Literacy Books" />
                            <p>Create a narrative that showcases your pupils’ creativity by completing a written book based upon a film.</p>
                        </div>
                        <div className="booksOtherGrid_item">
                            <h3 className="booksOtherGrid_item-title">Poetry</h3>
                            <img src={poetryImg} alt="Poetry Anthology Books" />
                            <p>Create a memorable poetry anthology with your class.</p>
                        </div>
                        <div className="booksOtherGrid_item">
                            <h3 className="booksOtherGrid_item-title">Non-Fiction</h3>
                            <img src={nonFictionImg} alt="Non-Fiction Books" />
                            <p>Bring any writing to life as a published book. A report on the Romans? Persuasive argument on climate change?</p>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="booksWebinar">
                <Container>
                    <div className="booksWebinar_desc">
                        <h2>Start Your Book</h2>
                        <p>Sign up your school to start your book and turn your pupils into published authors.</p>
                        <a href={process.env.TEACHER_SIGNUP_WRITE_URL} className="primary-btn">Sign Up Your School</a>
                    </div>
                    <div className="booksWebinar_quote">
                        <img src={quoteOpenImg} alt="" className="booksWebinar_quote-open booksWebinar_quote-q" />
                        <figure>
                            <blockquote>
                                <p>BoomWriter UK has transformed our writing. Our children are so excited to take part in a BoomWriter UK project, we even have children giving up their school holidays to take part.</p>
                                <p>The children are so proud when they hold the physical book and know that their work has been published.</p>
                            </blockquote>
                            <figcaption><strong>Alison Taylor</strong> <br/>Deputy Head Teacher &ndash; <em>Hillary Primary School</em></figcaption>
                        </figure>
                        <img src={quoteCloseImg} alt="" className="booksWebinar_quote-close booksWebinar_quote-q" />
                    </div>
                </Container>
            </section>
        </Layout>
        </>
    )
}

export default BooksPage